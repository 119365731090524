import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { VueEditor } from 'vue2-editor';

import VueApexCharts from 'vue-apexcharts';
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Buefy from 'buefy';
import App from './App.vue';
import './registerServiceWorker';
import store from './store';
import router from './router';
import './assets/scss/app.scss';
import '../node_modules/bulma/css/bulma.css';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import i18n from './i18n';

Vue.component('apexchart', VueApexCharts);

axios.defaults.withCredentials = true;
Vue.use(VueAxios, axios);

library.add(
  fas, far,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(require('moment'));

Vue.use(Buefy);
Vue.component('vue-editor', VueEditor);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
