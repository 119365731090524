import moment from 'moment';

export default {
  methods: {
    dateFormat(string) {
      const date = new Date(Date.parse(string));
      if (moment(date).isValid()) {
        return moment(date).format('DD.MM.YYYY');
      }
      return '-';
    },
    isBefore(check, ref) {
      return moment(check).isBefore(ref, 'day');
    },
    dateUnixFormat(string) {
      return moment.unix(string).format('DD.MM.YYYY');
    },
    US_to_EU_date(string) {
      return moment(string, 'MM-DD-YYYY').format('DD.MM.YYYY');
    },
    status(status, freq) {
      if (status === 10) return this.$t('common.status.assigned');
      if (status === 11 && freq === 0) return this.$t('common.status.paused');
      if (status === 11) return this.$t('common.status.active');
      if (status === 12) return this.$t('common.status.done');
      if (status === 99) return this.$t('common.status.deleted');
      return this.$t('common.status.no-status');
    },
    mail_validation(string) {
      const validRegex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
      if (string.match(validRegex)) {
        return true;
      }
      return false;
    },
    indexday_from_momentday(weekday) {
      if (weekday === 1) return 0;
      if (weekday === 2) return 1;
      if (weekday === 3) return 2;
      if (weekday === 4) return 3;
      if (weekday === 5) return 4;
      if (weekday === 6) return 5;
      if (weekday === 0) return 6;
      return weekday;
    },
  },
};
