<template>
  <section class="ResetPassword">
    <Navbar />
    <div class="hero is-small" v-bind:style="{ color: getColorByBgColor}">
      <div class="hero-body">
        <h1 class="admin-title">{{ $t('common.password') }}</h1>
        <h1 class="admin-title fat-text">{{ $t('common.set') }}</h1>
      </div>
    </div>
    <div class="background columns">
      <div class="column is-one-third is-offset-2 padding">
        <div class="space">
          <b-field :label="$t('forgot-pw.label1')">
            <input class="input" v-model="field1"
            type="password"
            :placeholder="$t('forgot-pw.label1')">
          </b-field>
          <b-field :label="$t('forgot-pw.label2')">
            <input type="password" class="input" v-model="field2"
            :placeholder="$t('forgot-pw.label2')">
          </b-field>
          <div class="level">
            <span class="level-left"></span>
            <span class="level-right">
              <Button :text="'Passwort setzen'" :type="'zoom'" :disabled="!canSubmit"
              @click="submit"/>
            </span>
          </div>
        </div>
      </div>
      <div class="column is-one-third margin-notification">
        <h2><b>{{ $t('forgot-pw.requirement') }}</b></h2>
        <h2>{{ $t('forgot-pw.r-1') }}
          <span  v-if="has8" class="icon icon-course-main">
            <font-awesome-icon :icon="['fa', 'check']" />
          </span>
        </h2>
        <h2>{{ $t('forgot-pw.r-2') }}
          <span v-if="hasd" class="icon icon-course-main">
            <font-awesome-icon :icon="['fa', 'check']" />
          </span>
        </h2>
        <h2>{{ $t('forgot-pw.r-3') }}
          <span v-if="hasuC" class="icon icon-course-main">
            <font-awesome-icon :icon="['fa', 'check']" />
          </span>
        </h2>
        <h2>{{ $t('forgot-pw.r-4') }}
          <span v-if="haslC" class="icon icon-course-main">
            <font-awesome-icon :icon="['fa', 'check']" />
          </span>
        </h2>
        <h2>{{ $t('forgot-pw.r-5') }}
          <span v-if="hassC" class="icon icon-course-main">
            <font-awesome-icon :icon="['fa', 'check']" />
          </span>
        </h2>
        <h2><b>{{ $t('forgot-pw.r-6') }}</b>
          <span v-if="matching" class="icon icon-course-main">
            <font-awesome-icon :icon="['fa', 'check']" />
          </span>
        </h2>
      </div>
    </div>
    <Footer />
  </section>
</template>

<script>
import Button from '../components/Common/Button.vue';
import Navbar from '../components/Common/Navbar.vue';
import Footer from '../components/Common/Footer.vue';

export default {
  components: {
    Navbar,
    Footer,
    Button,
  },

  data() {
    return {
      field1: '',
      field2: '',
      regex: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
      uC: '(?=.*?[A-Z])',
      lC: '(?=.*?[a-z])',
      sC: '(?=.*?[#?!@$%^&*-])',
      d: '(?=.*?[0-9])',
      min8: '.{8,}',
    };
  },

  computed: {
    isValid() {
      if (this.field1.match(this.regex)) {
        return true;
      }
      return false;
    },
    client() {
      return this.$store.getters['User/client'];
    },
    matching() {
      if (this.field1 === this.field2) {
        if (this.field1 === '' || this.field2 === '') {
          return false;
        }
        return true;
      }
      return false;
    },
    getColorByBgColor() {
      if (this.client === undefined) { return 'white'; }
      return (parseInt(this.client.scolor.replace('#', ''), 16) > 0xffffff / 2) ? '#000' : '#fff';
    },
    canSubmit() {
      if (this.matching && this.isValid) {
        return true;
      }
      return false;
    },
    hasuC() {
      if (this.field1.match(this.uC)) {
        return true;
      }
      return false;
    },
    haslC() {
      if (this.field1.match(this.lC)) {
        return true;
      }
      return false;
    },
    hassC() {
      if (this.field1.match(this.sC)) {
        return true;
      }
      return false;
    },
    hasd() {
      if (this.field1.match(this.d)) {
        return true;
      }
      return false;
    },
    has8() {
      if (this.field1.match(this.min8)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    submit() {
      this.$store.dispatch('User/set_pw', this.field1).then((response) => {
        if (response) {
          this.$buefy.toast.open({
            message: 'Passwort gesetzt!',
            type: 'is-success',
          });
          this.$router.push({ name: 'Dashboard' });
        } else {
          this.$buefy.toast.open({
            message: 'Passwort konnte nicht gesetzt werden.',
            type: 'is-danger',
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ResetPassword {
  margin-top: 3.899rem !important;
  font-family: "Roboto";
  .hero {
    background-color: var(--secondary-color);
    color: var(--secondary-color-text);
    padding-top: 0 !important;
  }
  .columns {
    margin: 0;
  }
  .notification {
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-weight: 600;
    color: #363636;
  }
  .margin-notification {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  Button {
    margin-top: 2rem;
  }
  .fat-text {
    font-weight: 900;
  }
  .space {
    padding: 2rem 1rem;
  }
  .icon {
    display: inline;
    padding-left: .5rem;
  }
  .padding {
    padding: 3rem;
  }
  .admin-title {
    display: inline;
    text-transform: uppercase;
    font-size: 3em;
    padding-left: 1rem;
    padding-top: 2rem;
  }
}
</style>
