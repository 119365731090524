import axios from 'axios';
import helpers from '../../mixins/helpers';

const state = {
  g: [],
  sg: [{ members: [], assigned: [] }],
  cm: [],
  group_selected: false,
  client_selected: false,
  selected_client: {
    name: '',
    id: '',
    index: null,
  },
  current_menu: {
    id: 0,
    title: '',
  },
  edit_client: -1,
};
const getters = {
  get_groups: () => state.g,
  get_members: () => state.sg[0].members,
  get_assignments: () => state.sg[0].assigned.filter((assignment) => assignment.status !== 'deleted'),
  get_group: () => state.sg[0],
  get_member: () => state.cm,
  get_sg_id: () => state.sg[0].id,
  get_sg: () => state.sg[0],
  get_group_selected: () => state.group_selected,
  get_client_selected: () => state.client_selected,
  get_selected_client: () => state.selected_client,
  get_current_menu: () => state.current_menu,
  get_edit_client: () => state.edit_client,
};
const actions = {
  async load(context, payload) {
    const result = await axios.get(`${helpers.apihost()}groups/${payload}`);
    if (result.data) {
      context.commit('set_groups', result.data);
      return true;
    }
    return false;
  },
  async load_group(context, payload) {
    const result = await axios.get(`${helpers.apihost()}group/${payload}`);
    if (result.data) {
      context.commit('set_group', result.data);
      return true;
    }
    return false;
  },
  async add_member(context) {
    state.cm.group_id = state.sg[0].id;
    const result = await axios.put(`${helpers.apihost()}user`, state.cm, {
      headers: {
        'Content-type': 'application/json',
      },
    });
    if (result) {
      context.commit('set_member', result.data);
      return result.data;
    }
    return false;
  },
  async del_member(context, payload) {
    const result = await axios.post(`${helpers.apihost()}del_group_member`, {
      params: {
        member: payload.member,
        group: payload.group,
        del_assignments: payload.del_assignments,
      },
    });
    if (result) {
      return true;
    }
    return false;
  },
  async del_group(context, payload) {
    const result = await axios.post(`${helpers.apihost()}del_group`, {
      params: {
        group: payload.group,
        name: payload.name,
      },
    });
    if (result) {
      context.commit('del_group', payload.name);
      return true;
    }
    return false;
  },
  async changename(context, payload) {
    const result = await axios.post(`${helpers.apihost()}change_group_name`, {
      params: {
        new_name: payload.name,
        groupID: payload.groupID,
      },
    });
    if (result) {
      return true;
    }
    return false;
  },
  async change_user(context, payload) {
    console.log(payload);
    const result = await axios.post(`${helpers.apihost()}change_user`, {
      params: {
        new_user: payload,
      },
    });
    if (result) {
      return true;
    }
    return false;
  },
  change_group_selected(context, payload) {
    context.commit('set_group_selected', payload);
  },
  change_client_selected(context, payload) {
    context.commit('set_client_selected', payload);
  },
  change_selected_client(context, payload) {
    context.commit('set_selected_client', payload.client);
    context.commit('set_current_menu', payload.currentMenu);
    context.commit('set_edit_client', payload.editClient);
  },
  change_current_menu_name(context, payload) {
    context.commit('set_current_menu_name', payload);
  },
};
const mutations = {
  set_group_selected(s, payload) {
    state.group_selected = payload;
  },
  set_client_selected(s, payload) {
    if (payload === false) state.group_selected = payload;
    state.client_selected = payload;
  },
  set_selected_client(s, payload) {
    state.selected_client = payload;
  },
  set_current_menu(s, payload) {
    state.current_menu = payload;
  },
  set_edit_client(s, payload) {
    state.edit_client = payload;
  },
  set_current_menu_name(s, payload) {
    state.current_menu.title = payload;
    console.log(payload);
  },
  set_member(s, payload) {
    state.sg[0].members.push(payload);
  },
  set_groups(s, payload) {
    state.g = payload;
  },
  set_group(s, payload) {
    state.sg = payload;
  },
  set_sg(s, payload) {
    state.sg[0].id = payload;
  },
  async add_group(s, payload) {
    const group = { name: { p: payload.p, clientId: state.selected_client.id }, member: [] };
    const g = await axios.put(`${helpers.apihost()}addgroup`, group);
    state.g.push(g.data);
  },
  del_member(s, payload) {
    let i;
    for (i = 0; i < state.g.length; i += 1) {
      if (state.g[i].name === payload.group) {
        let k;
        for (k = 0; k < state.g[i].member.length; k += 1) {
          console.log(state.g[i].member[k]);
          console.log('is being deleted');
          state.g[i].member.splice(k, 1);
        }
      }
    }
  },
  del_group(s, payload) {
    let i;
    for (i = 0; i < state.g.length; i += 1) {
      if (state.g[i].name === payload) {
        state.g.splice(i, 1);
      }
    }
  },
  new_user() {
    const obj = {
      attention: '',
      firstname: '',
      lastname: '',
      u: '',
      p: '',
      group_id: '',
      client_id: state.sg[0].client_id,
    };
    state.cm = obj;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
