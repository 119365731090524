import Vue from 'vue';
import Vuex from 'vuex';
import User from './modules/user';
import userProcess from './modules/userProcess';
import Clients from './modules/clients';
import Groups from './modules/groups';
import Uploads from './modules/uploads';
import Account from './modules/account';
import Teachers from './modules/teachers';
import Plans from './modules/plans';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    User,
    userProcess,
    Clients,
    Groups,
    Uploads,
    Account,
    Teachers,
    Plans,
  },
});
