import axios from 'axios';
import helpers from '../../mixins/helpers';
/* eslint-disable no-else-return */

const state = {
  c: [],
  nc: {},
  login_client: {},
};
const getters = {
  get_clients: () => state.c,
  get_new_client: () => state.nc,
  login_client: () => state.login_client,
};
const actions = {
  async load(context) {
    const result = await axios.get(`${helpers.apihost()}clients`);

    if (result.data) {
      context.commit('set_clients', result.data);
      return true;
    }
    return false;
  },
  async load_client(context, param) {
    const result = await axios.get(`${helpers.apihost()}get_client/${param}`);

    if (result.data) {
      context.commit('set_login_client', result.data);
      return true;
    }
    return false;
  },
  async save(context, payload) {
    if (payload.new_client) {
      const result = await axios.put(`${helpers.apihost()}save_client`, state.nc, {
        headers: {
          'Content-type': 'application/json',
        },
      });
      if (result) {
        state.nc = [];
        context.commit('add_client', result.data);
        return true;
      }
      return false;
    } else {
      const result = axios.put(`${helpers.apihost()}save_client`, state.c[payload.index], {
        headers: {
          'Content-type': 'application/json',
        },
      });
      if (result) {
        return true;
      }
      return false;
    }
  },
};
const mutations = {
  set_clients(s, payload) {
    state.c = payload;
  },
  set_login_client(s, payload) {
    state.login_client = payload;
  },
  add_client(s, payload) {
    state.c.push(payload);
  },
  new_client() {
    state.nc = {
      name: '',
      pcolor: '#ffffff',
      scolor: '#ffffff',
      pcolortext: '#ffffff',
      scolortext: '#ffffff',
      logo: '',
      dashboard: {
        de: {
          name: 'Deutsch',
          dbline: '',
          dbtext: '',
          videolink: '',
          videoposter: '',
        },
        en: {
          name: 'Englisch',
          dbline: '',
          dbtext: '',
          videolink: '',
          videoposter: '',
        },
      },
      dbline: '',
      dbtextl: '',
      dbtextr: '',
      videolink: '',
      videoposter: '',
      footer_txt: '<p></p>',
      login_param: '',
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
