import axios from 'axios';
import helpers from '@/mixins/helpers';
/* eslint-disable no-else-return */
/* eslint-disable max-len */

const state = {
  loading: true,
  teachers: [],
  search_list: [],
};
const getters = {
  get_clients: () => state.c,
  loading: () => state.loading,
  get_teachers: () => {
    const ts = [];
    const result = state.teachers;
    for (let i = 0; i < result.length; i += 1) {
      console.log(result[i].lastname);
      ts.push({
        id: result[i].id,
        firstname: result[i].firstname,
        lastname: result[i].lastname,
      });
    }
    return ts;
  },
  get_available_teachers: () => state.teachers.filter((teacher) => teacher.isAbsent !== true),
  get_search_list: () => state.search_list.filter((teacher) => teacher.isAbsent !== true),
  absent_teachers: () => state.teachers.filter((teacher) => teacher.absent.length !== 0),
  get_currently_absent_teachers: () => state.teachers.filter((teacher) => teacher.isAbsent === true),
};
const actions = {
  async load(context) {
    const result = await axios.get(`${helpers.apihost()}get_teachers`);
    if (result.data) {
      context.commit('set_teachers', result.data);
      return true;
    }
    return false;
  },
  async add_absent_teacher(context, teacher) {
    const result = await axios.put(`${helpers.apihost()}add_absent_teacher`, teacher);
    if (result) {
      return true;
    }
    return false;
  },
  async del_absent_entry(context, teacher) {
    const result = await axios.put(`${helpers.apihost()}del_absent_entry`, teacher);
    if (result) {
      return true;
    }
    return false;
  },
  async search_teacher(context, param) {
    const result = await axios.get(`${helpers.apihost()}search_teacher/${param}`);
    if (result) {
      context.commit('set_teacher_search_list', result.data);
      return result;
    }
    return false;
  },
};
const mutations = {
  set_teachers(s, payload) {
    state.teachers = payload;
    state.loading = false;
  },
  set_teacher_search_list(s, payload) {
    state.search_list = payload;
  },
  new_teacher() {
    state.nc = {
      name: '',
      pcolor: '#ffffff',
      scolor: '#ffffff',
      pcolortext: '#ffffff',
      scolortext: '#ffffff',
      logo: '',
      dashboard: {
        de: {
          name: 'Deutsch',
          dbline: '',
          dbtext: '',
          videolink: '',
          videoposter: '',
        },
        en: {
          name: 'Englisch',
          dbline: '',
          dbtext: '',
          videolink: '',
          videoposter: '',
        },
      },
      dbline: '',
      dbtextl: '',
      dbtextr: '',
      videolink: '',
      videoposter: '',
      footer_txt: '<p></p>',
      login_param: '',
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
