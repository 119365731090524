import axios from 'axios';
import helpers from '@/mixins/helpers';
import moment from 'moment';
/* eslint-disable no-else-return */
/* eslint-disable max-len */

const state = {
  loading: true,
  latest_plan: {},
  selected_date: '',
  new_plan: {},
};
const getters = {
  get_latest_plan: () => state.latest_plan,
  loading: () => state.loading,
  get_weekday_from_plan: () => {
    const weekday = moment(state.selected_date).day();
    console.log(state.selected_date);
    if (weekday === 1) return 'Montags';
    if (weekday === 2) return 'Dienstags';
    if (weekday === 3) return 'Mittwochs';
    if (weekday === 4) return 'Donnerstags';
    if (weekday === 5) return 'Freitags';
    if (weekday === 6) return 'Samstags';
    if (weekday === 0) return 'Sonntags';
    return '';
  },
};
const actions = {
  async get_plan(context, payload) {
    const p = { date: payload };
    state.selected_date = payload;
    const result = await axios.post(`${helpers.apihost()}get_plan`, p);
    if (result.data) {
      context.commit('set_latest_plan', result.data);
      return true;
    }
    state.latest_plan = payload;
    state.loading = false;
    return false;
  },
  async save_plan(context) {
    const result = await axios.post(`${helpers.apihost()}save_plan`, state.latest_plan);
    if (result.data) {
      context.commit('set_latest_plan', result.data);
      return true;
    }
    return false;
  },
  async send_plan(context, payload) {
    const p = { date: payload };
    const result = await axios.post(`${helpers.apihost()}makeplan`, p);
    if (result.data) {
      // context.commit('set_latest_plan', result.data);
      return true;
    }
    return false;
  },
  async make_plan(context, payload) {
    const p = { date: payload };
    const result = await axios.get(`${helpers.apihost()}make_plan`, p);
    if (result.data) {
      context.commit('set_new_plan', result.data);
      return true;
    }
    return false;
  },
};

const mutations = {
  mutate_remove_double(s, payload) {
    const ts = ['offen', 'Nur Bemerkung', 'entfällt', '-', '', ' '];
    state.latest_plan.subs.forEach((entry) => {
      if (entry.substitute.lastname === payload.substitute.lastname && ts.includes(payload.substitute.lastname) === false
          && entry.class !== payload.class && entry.hour === payload.hour) {
        entry.substitute.lastname = 'offen'; // eslint-disable-line no-param-reassign
      }
    });
  },

  set_latest_plan(s, payload) {
    const classes = [];
    // const sortclasses = ['1a', '1b', '1c', '2a', '2b', '2c', '3a', '3b', '3c', '4a', '4b', '4c', '5a', '5b', '5c', '6a', '6b', '6c', '7a', '7b', '7c', '8a', '8b', '8c', '9a', '9b', '9c', '10a', '10b', '10c', '11a', '11b', '11c', '12a', '12b', '12c', 'VK'];
    if (Object.entries(payload).length > 0) {
      payload.subs.forEach((e) => {
        // console.log(e.class);
        if (classes.indexOf(e.class) === -1) {
          classes.push(e.class);
        }
      });
    }

    classes.sort((a, b) => {
      let aa = 0;
      let bb = 0;
      if (a === 'VK') {
        return -1;
      }
      if (a[1] > -1 && a[1] < 10) {
        aa = a.substring(0, 2);
      } else {
        aa = a.substring(0, 1);
      }
      if (b[1] > -1 && b[1] < 10) {
        bb = b.substring(0, 2);
      } else {
        bb = b.substring(0, 1);
      }
      return bb - aa;
    });
    // classes.sort();
    console.log(classes);

    // const classes = ['1a', '1b', '1c', '1c/2c', '2c/1c', '1a/2a', '1b/2b', '1b/3b', '2a', '2b', '2a/2b', '2b/3b', '2b/4b', '2c', '3a', '3a/3b', '3a/4a', '3a/3b/4a/4b', '3b', '3c', '4a', '4a/4c', '4b', '4a/4b', '4c', '4a/4b/5a/5b/6a/6b', '5a', '5b', '5a/5b', '5a/5b/6a', '5a/5b/6a/6b', '5c', '5c/6c', '6a/6b/7a', '6a', '6b', '6a/6b', '6c', '7a', '7b', '7a/7b', '7c', '7c/8c', '7a/7b/8a/8b', '8a', '8b', '8a/8b', '8c', '9a', '9b', '9a/9b', '9c', '9c/10c', '9a/9b/10a/10b/11a/11b/12a/12b', '10a', '10b', '10a/10b', '10c', '11a', '11b', '11a/11b', '11c', '12a', '12b', '12a/12b', '12c', 'VK'];
    const ol = [];
    if (Object.entries(payload).length > 0) {
      classes.forEach((c) => {
        payload.subs.forEach((e) => {
          if (e.class === c) {
            ol.push(e);
          }
        });
      });
      state.latest_plan = payload;
      state.latest_plan.subs = ol;
    }
    if (Object.entries(payload).length === 0) {
      state.latest_plan = {};
    }
    state.loading = false;
  },
  set_new_plan(s, payload) {
    state.new_plan = payload;
  },
  save_sub(s, payload) {
    state.latest_plan.subs.push(payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
