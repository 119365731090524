<template>
<section class="login-modal">
  <div class="modal is-active" v-if="showModal">
    <div @click="toggleModal()" class="modal-background"></div>
    <div class="modal-content modal-card login-card">
      <div class="blur-login"></div>
      <div class="container">
        <div class="registration-div" v-if="registration" >
          <h3 @click="registration = !registration"
          class="subtitle">Registrierung</h3>
        </div>
        <h3 v-else>Login</h3>
        <form v-if="registration" class="columns is-desktop">
          <div class="column inline-flex">
            <div class="p-2">
              <b-field label="Vorname">
                <b-input placeholder="Vorname"
                style="max-width: 500px;"
                type="name"
                v-model="newuser.firstname"
                icon="account"
                autocomplete="on">
                </b-input>
              </b-field>
              <b-field label="Name">
                <b-input placeholder="Name"
                style="max-width: 500px;"
                type="name"
                v-model="newuser.lastname"
                icon="account"
                autocomplete="on">
                </b-input>
              </b-field>
            </div>
            <div class="p-2">
              <b-field label="E-Mail">
                <b-input placeholder="E-Mail"
                style="max-width: 500px;"
                type="email"
                v-model="newuser.u"
                icon="email"
                autocomplete="on">
                </b-input>
              </b-field>
            </div>
            <div class="p-2">
              <b-field label="Passwort">
                <b-input placeholder="Passwort"
                @keyup.native.enter="login()"
                style="max-width: 500px;"
                type="password"
                v-model="newuser.p"
                icon="key"
                autocomplete="on">
                </b-input>
              </b-field>
              <b-field label="Passwort wiederholen">
                <b-input placeholder="Passwort wiederholen"
                @keyup.native.enter="login()"
                style="max-width: 500px;"
                type="password"
                v-model="newuser.passwordcheck"
                icon="key"
                autocomplete="on">
                </b-input>
              </b-field>
            </div>
          </div>
          <div class="pw-check column">
            <h5><b>Das Passwort muss folgendes beinhalten:</b></h5>
            <h5>8 Zeichen
              <span  v-if="has8" class="icon icon-course-main">
                <font-awesome-icon :icon="['fa', 'check']" />
              </span>
            </h5>
            <h5>mind. 1 Zahl
              <span v-if="hasd" class="icon icon-course-main">
                <font-awesome-icon :icon="['fa', 'check']" />
              </span>
            </h5>
            <h5>mind. 1 Großbuchstaben
              <span v-if="hasuC" class="icon icon-course-main">
                <font-awesome-icon :icon="['fa', 'check']" />
              </span>
            </h5>
            <h5>mind. 1 Kleinbuchstaben
              <span v-if="haslC" class="icon icon-course-main">
                <font-awesome-icon :icon="['fa', 'check']" />
              </span>
            </h5>
            <h5>mind. 1 Sonderzeichen(!,?, ... )
              <span v-if="hassC" class="icon icon-course-main">
                <font-awesome-icon :icon="['fa', 'check']" />
              </span>
            </h5>
            <h5><b>Die Eingaben müssen übereinstimmen.</b>
              <span v-if="matching" class="icon icon-course-main">
                <font-awesome-icon :icon="['fa', 'check']" />
              </span>
            </h5>
          </div>
        </form>
        <form v-else>
          <b-field label="Name">
            <b-input placeholder="Name"
            style="max-width: 500px;"
            type="email"
            v-model="user"
            icon="email"
            autocomplete="on">
            </b-input>
          </b-field>
          <b-field label="Passwort">
            <b-input placeholder="Passwort"
            @keyup.native.enter="login()"
            style="max-width: 500px;"
            type="password"
            v-model="password"
            icon="key"
            autocomplete="on">
            </b-input>
          </b-field>
        </form>
        <div v-if="!registration" @click="toggleForgot()" class="pointer">
          <p>Passwort vergessen?</p>
        </div>
        <div class="pt-4" v-if="registration">
          <Button @click="toggleModal()" :text="'Abbrechen'" />
          <Button :disabled="!isValid"
          @click="register()" :text="'Registrieren und Testen'" />
        </div>
        <div class="button-sec" v-else>
          <Button @click="toggleModal()" :text="'Abbrechen'" />
          <Button @click="login()" :text="'Login'" />
        </div>
      </div>
    </div>
  </div>
  <div class="modal is-active" v-if="forgotModal">
    <div class="modal-background"></div>
      <div class="modal-content modal-card login-card">
        <div class="container">
          <h3 class="subtitle">Passwort vergessen</h3>
          <b-field label="E-Mail">
            <b-input placeholder="Bitte E-Mail eintragen." v-model="forgot_mail"></b-input>
          </b-field>
          <Button @click="toggleForgot()" :text="'Abbrechen'" />
          <Button :text="'E-Mail senden'" :type="'zoom'" :style="'background-color: #c0c0c0;'"
            @click="forgot_pw()"/>
        </div>
      </div>
    </div>
  <Button :text="$t('navbar.login')" :type="'on-dark'" @click="toggleModal()" />
</section>
</template>

<script>
import Button from '../Button.vue';

export default {
  components: {
    Button,
  },

  // created() {
  //   if (this.$route.name === 'Register') {
  //     this.showModal = true;
  //     this.registration = true;
  //   }
  // },

  data() {
    return {
      showModal: false,
      forgotModal: false,
      registration: false,
      user: '',
      password: '',
      forgot_mail: '',
      newuser: {
        u: '',
        firstname: '',
        lastname: '',
        p: '',
        passwordcheck: '',
      },
      regex: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
      uC: '(?=.*?[A-Z])',
      lC: '(?=.*?[a-z])',
      sC: '(?=.*?[#?!@$%^&*-])',
      d: '(?=.*?[0-9])',
      min8: '.{8,}',
    };
  },

  computed: {
    isValid() {
      if (this.newuser.firstname.length > 1 && this.newuser.lastname.length > 1) {
        if (this.matching && this.pValid) {
          if (this.newuser.u.length > 3) {
            return true;
          }
        }
      }
      return false;
    },
    pValid() {
      if (this.newuser.p.match(this.regex)) {
        return true;
      }
      return false;
    },
    matching() {
      if (this.newuser.p === this.newuser.passwordcheck) {
        if (this.newuser.p === '' || this.newuser.passwordcheck === '') {
          return false;
        }
        return true;
      }
      return false;
    },
    hasuC() {
      if (this.newuser.p.match(this.uC)) {
        return true;
      }
      return false;
    },
    haslC() {
      if (this.newuser.p.match(this.lC)) {
        return true;
      }
      return false;
    },
    hassC() {
      if (this.newuser.p.match(this.sC)) {
        return true;
      }
      return false;
    },
    hasd() {
      if (this.newuser.p.match(this.d)) {
        return true;
      }
      return false;
    },
    has8() {
      if (this.newuser.p.match(this.min8)) {
        return true;
      }
      return false;
    },
  },

  methods: {
    login() {
      const payload = { user: this.user, password: this.password };
      this.$store.dispatch('User/login', payload).then((response) => {
        console.log('response', response.length);
        if (response) {
          this.$router.push('dashboard');
          this.$i18n.locale = this.$store.getters['User/user'].lang;
          const bodyStyles = document.body.style;
          bodyStyles.setProperty('--primary-color', this.$store.getters['User/client'].pcolor);
          bodyStyles.setProperty('--secondary-color', this.$store.getters['User/client'].scolor);
          bodyStyles.setProperty('--primary-color-text', this.$store.getters['User/client'].pcolortext);
          bodyStyles.setProperty('--secondary-color-text', this.$store.getters['User/client'].scolortext);
        } else {
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Passwort oder Nutzername falsch',
            type: 'is-danger',
          });
        }
      });
    },
    register() {
      this.$store.dispatch('User/register', this.newuser).then((response) => {
        if (response) {
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Registrierung abgeschlossen, sie erhalten in Kürze eine E-Mail.',
            type: 'is-success',
          });
        } else {
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Etwas ist schief gelaufen, bitte melden Sie sich bei uns, oder probieren Sie es später erneut.',
            type: 'is-danger',
          });
        }
      });
    },
    toggleModal() {
      this.showModal = !this.showModal; // deactivated for website building
      this.registration = false;
    },
    toggleForgot() {
      this.forgotModal = !this.forgotModal;
    },
    forgot_pw() {
      this.$store.dispatch('User/forgot_pw', this.forgot_mail).then((response) => {
        if (response) {
          this.$buefy.toast.open({
            message: 'E-Mail gesendet!',
            type: 'is-success',
          });
        } else {
          this.$buefy.toast.open({
            message: 'E-Mail konnte nicht geschickt werden.',
            type: 'is-danger',
          });
        }
      });
      this.forgotModal = !this.forgotModal;
    },
  },
};
</script>

<style lang="scss" theme="a">
.login-modal {
  .login-card {
    color: black;
    background-color: white;
    width: fit-content !important;
    border-radius: 15px;
    padding: 3rem 5rem;
    font-weight: 450;
    transition: width 2s;
    p {
      font-size: 1rem;
      text-transform: none;
      color: black;
    }
    .registration-div {
      padding-bottom: 1.5rem;
    }
  }

  .pw-check {
    padding-left: 1rem;
  }

  .inline-flex {
    display: inline-flex;
  }

  .help {
    font-size: .4rem !important;
    font-weight: 400;
  }

  h5 {
    .icon {
      height: 80%;
    }
  }

  h3 {
    font-weight: 450;
    font-size: 1.6rem;
    margin-bottom: 1.4rem;
  }

  .button-sec {
    margin-top: 2rem;
  }

  .margin-button {
    margin-left: 2rem;
  }

  .button {
    background-color: white;
    color: black;
    border: 2px solid #c0c0c0;
    &:hover {
      border: 2px solid black;
      color: black;
      background: #c0c0c0;
    }
    cursor: pointer;
    transition: all 0.25s ease;
  }

  a {
    color: black;
  }

  a:hover {
    color: hsl(48, 100%, 50%);
  }

  .pointer:hover {
    cursor: pointer;
  }
}
</style>

<style lang="scss" theme="b">
.login-modal {
  h3 {
    color: white;
  }
  .login-card {
    transition: all 1s;
    color: black;
    border: .5px solid var(--secondary-color);
    background-color: black;
    width: fit-content !important;
    border-radius: 10px;
    padding: 3rem 5rem;
    font-weight: 450;
    transition: width 2s;
    p {
      font-size: 1rem;
      text-transform: none;
      color: white;
    }
    .registration-div {
      padding-bottom: 1.5rem;
    }
  }

  .pw-check {
    padding-left: 1rem;
  }

  .inline-flex {
    display: inline-flex;
  }

  .help {
    font-size: .4rem !important;
    font-weight: 400;
  }

  label {
    color: white;
  }
  input {
    background-color: black !important;
    border-radius: 10px;
    color: white !important;
  }
  textarea {
    background-color: black;
    border-radius: 10px;
    color: white;
  }

  h5 {
    .icon {
      height: 80%;
    }
  }

  h3 {
    font-weight: 450;
    font-size: 1.6rem;
    margin-bottom: 1.4rem;
  }

  .button-sec {
    margin-top: 2rem;
  }

  .margin-button {
    margin-left: 2rem;
  }

  .button {
    background-color: white;
    color: black;
    border: 2px solid #c0c0c0;
    &:hover {
      border: 2px solid black;
      color: black;
      background: #c0c0c0;
    }
    cursor: pointer;
    transition: all 0.25s ease;
  }

  a {
    color: black;
  }

  .pointer:hover {
    cursor: pointer;
  }
  .blur-login {
    position: absolute;
    width: 284px;
    height: 127px;
    background: rgba(200, 25, 253, 0.81);
    filter: blur(100px);
    animation: blur1 20s ease infinite;
    -webkit-animation: blur1 20s ease infinite;
    @keyframes blur1 {
      0% {
        transform: translateX(0vw);
        transform: translateY(0vw);
      }
      50% {
        transform: translateX(50vw);
        transform: translateY(10vw);
      }
      100% {
        transform: translateX(0vw);
        transform: translateY(0vw);
      }
    }
  }
}
</style>
