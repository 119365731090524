<template>
<div>
  <Navbar />
    <section class="hero fixed-nav profile">
      <div class="hero-body">
        <h1 class="admin-title">{{ $t('dashboard.standby') }} </h1>
        <h1 class="admin-title fat-text">{{ client.name }}</h1>
        <div class="columns">
          <div class="column is-one-quarter">
            <b-field label="für den Tag:"></b-field>
            <b-datepicker
                v-model="plan_for_date"
                :key="plan_for_date"
                :data="plan_for_date"
                placeholder="Hier klicken..."
                icon="calendar-today"
                :unselectable-days-of-week="[0, 6]"
                close-on-click>
            </b-datepicker>
            <button text="" :type="'zoom'" @click="go_back">
              <font-awesome-icon :icon="['fa', 'angle-left']"/>
            </button>
            <button text="vor" :type="'zoom'" :icon="'fa-angle-right'" @click="go_next">
              <font-awesome-icon :icon="['fa', 'angle-right']"/>
            </button>
          </div>
        </div>
        <b>Krank:</b> <p class="inline" v-html="latest_plan.ill"></p> <br>
        <b>Beurlaubt:</b> <p class="inline" v-html="latest_plan.excused"></p> <br>
        <b>Fahrt:</b> <p class="inline" v-html="latest_plan.trip"></p> <br><br>

        <b-table class="mt-1" v-if="!loading"
          :data="latest_plan.subs"
          :row-class="(row, index) => color(row)"
          :bordered="true"
          :striped="false"
          :narrowed="false"
          :hoverable="true"
          :loading="false"
          :focusable="false"
          :mobile-cards="true"
          :paginated="false">
          <b-table-column field="class" label="Klasse" sortable v-slot="props">
            {{ props.row.class }}
          </b-table-column>
          <b-table-column field="hour" label="Stunde" sortable v-slot="props">
            {{ props.row.hour }}
          </b-table-column>
          <b-table-column field="subject" label="Fach" sortable v-slot="props">
            {{ props.row.subject }}
          </b-table-column>
          <b-table-column field="missing" label="Fehlende(r)" sortable v-slot="props">
            <b-tooltip :label="props.row.missing.subjects.toString()" position="is-right">
              {{props.row.missing.lastname}}
            </b-tooltip>
          </b-table-column>

          <b-table-column field="substitute" label="Vertretung" v-slot="props">
            <b-dropdown v-if="edit" aria-role="list" @input="substchange(props.row)"
            v-model="props.row.substitute.lastname" :scrollable="true" :max-height="400">
              <template #trigger="{ active }">
                <b-button :label="props.row.substitute.lastname"
                  :icon-right="active ? 'menu-up' : 'menu-down'" />
              </template>
              <p class="pl-2"><b>Bereitschaft</b></p>
              <b-dropdown-item v-for="alternative in props.row.alternatives"
              :key="alternative.firstname" aria-role="listitem" :value="alternative.lastname">
                {{ alternative.firstname + ' ' + alternative.lastname + ' ('
                  + alternative.standby_hours + ')'}}
              </b-dropdown-item>
              <b-dropdown-item aria-role="listitem" :value="''">
                <b>Nur Bemerkung</b>
              </b-dropdown-item>
              <b-dropdown-item aria-role="listitem" :value="'-'">
                <b>entfällt</b>
              </b-dropdown-item>
              <hr>
              <p class="pl-2"><b>Stunde fällt aus</b></p>
              <b-dropdown-item v-for="free_teacher in props.row.free_teachers" :key="free_teacher"
              aria-role="listitem" :value="free_teacher.lastname">
                {{ free_teacher.lastname }}
              </b-dropdown-item>
              <hr>
              <p class="pl-2"><b>Alle Lehrer</b></p>
              <b-dropdown-item v-for="(teacher, index) in available_teachers" :key="index"
              aria-role="listitem" :value="teacher.lastname">
                {{ teacher.fullName }}
              </b-dropdown-item>
            </b-dropdown>
            <span v-else>
              <b-tooltip :label="props.row.substitute.subjects.toString()" position="is-right">
                {{ props.row.substitute.lastname }}
              </b-tooltip>
            </span>
            <font-awesome-icon :icon="['fa', 'check']" @click="show_reg_plan"/>
          </b-table-column>
          <b-table-column field="comment" label="Bemerkung" v-slot="props">
            <b-input v-if="edit" v-model="props.row.comment" type="textarea"></b-input>
            <p v-else>{{ props.row.comment }}</p>
          </b-table-column>
          <b-table-column field="noprint" label="nop" v-slot="props" icon="">
            <b-checkbox v-model="props.row.noprint">
            </b-checkbox>
          </b-table-column>
          <b-table-column field="fix" label="fix" v-slot="props">
            <b-checkbox v-model="props.row.fix">
            </b-checkbox>
          </b-table-column>
        </b-table>
        <br>
        <Button :disabled="is_old" :text="'Vertretung eintragen'" @click="add_sub" />
        <Button :disabled="is_old" :text="'Plan aktualisieren'" @click="make_plan" />
        <Button :disabled="is_old" :text="edit ? 'Bearbeiten beenden' : 'Bearbeiten'"
          @click="edit = !edit" />
        <Button icon-left="check" :text="'Plan versenden'" @click="send_plan" />
        <Button v-if="edit" :text="'Speichern'" @click="save_plan" />
      </div>
    </section>

    <!-- PANEL Vertretung Planen -->
    <div class="modal is-active" v-if="edit_modal">
      <div class="modal-background" @click="reset_selected"></div>
      <div class="modal-card">
        <header class="modal-card-head has-text-centered">
          <p class="title card-title">Vertretung planen für den {{ latest_plan.daydate }}</p>
        </header>
        <section class="modal-card-body">
          <div class="mt-5">
            <b-field label="Klasse">
              <b-input value="" v-model="entry.class"></b-input>
            </b-field>
            <b-field label="Stunde">
              <b-input value="" v-model="entry.hour"></b-input>
            </b-field>
            <b-field label="Fehlend">
              <b-autocomplete :key="this.hotloading"
                v-model="name"
                :placeholder="$t('teacher-management.search-placeholder')"
                :keep-first="true"
                :open-on-focus="true"
                :data="search_teachers"
                @select="select_missing($event)"
                field="fullName"
                :empty="[]"
                :clearable="true"
                @input="search(name)">
                <template #empty>Keine Ergebnisse</template>
              </b-autocomplete>
            </b-field>
            <b-field label="Vertretung">
              <b-autocomplete :key="this.hotloading"
                v-model="name_sub"
                :placeholder="$t('teacher-management.search-placeholder')"
                :keep-first="true"
                :open-on-focus="true"
                :data="search_teachers"
                @select="select_sub($event)"
                field="fullName"
                :empty="[]"
                :clearable="true"
                @input="search_sub(name)">
                <template #empty>Keine Ergebnisse</template>
              </b-autocomplete>
            </b-field>
            <b-field label="Bemerkung">
              <b-input value="" v-model="entry.comment"></b-input>
            </b-field>
          </div>
        </section>
        <footer class="modal-card-foot">
          <Button :text="$t('common.cancel')" :type="'zoom'"
          @click="reset_selected" />
          <Button :text="$t('common.save')" :type="'zoom'"
          @click="save_sub" />
        </footer>
      </div>
    </div>

    <!-- PANEL Stundenplan -->
    <div class="modal is-active" v-if="plan_modal">
      <div class="modal-background" @click="reset_selected"></div>
      <div class="modal-card">
        <header class="modal-card-head has-text-centered">
          <p class="title card-title">Stundenplan für den {{ latest_plan.daydate }}</p>
        </header>
        <section class="modal-card-body">
          <div class="mt-5">

          </div>
        </section>
        <footer class="modal-card-foot">
          <Button :text="$t('common.cancel')" :type="'zoom'"
          @click="close_plan_modal" />
        </footer>
      </div>
    </div>

  <Footer />
</div>
</template>

<script>
/* eslint-disable dot-notation */
import moment from 'moment';
import formatting from '../mixins/formatting';
import Navbar from '../components/Common/Navbar.vue';
import Footer from '../components/Common/Footer.vue';
import Button from '../components/Common/Button.vue';

export default {
  components: {
    Navbar,
    Footer,
    Button,
  },
  mixins: [formatting],
  data() {
    return {
      plan_for_date: new Date(),
      table_selected: {},
      edit: false,
      edit_modal: false,
      plan_modal: false,
      entry: {},
      hotloading: false,
      name: '',
      name_sub: '',
    };
  },

  created() {
    // this.$store.dispatch('Plans/load_latest_plan');
    this.$store.dispatch('Account/load');
    this.$store.dispatch('Teachers/load');
  },

  watch: {
    plan_for_date() {
      this.get_plan();
    },
  },

  computed: {
    plan() {
      return this.$store.getters['Plans/get_latest_plan'];
    },
    current_weekday() {
      return this.$store.getters['Plans/get_weekday_from_plan'];
    },
    client() {
      return this.$store.getters['Clients/login_client'];
    },
    loading() {
      return this.$store.getters['Plans/loading'];
    },
    latest_plan() {
      return this.$store.getters['Plans/get_latest_plan'];
    },
    available_teachers() {
      return this.$store.getters['Teachers/get_available_teachers'];
    },
    get_completete_search_list() {
      return this.$store.getters['Teachers/get_completete_search_list'];
    },
    search_teachers() {
      if (this.$store.getters['Teachers/get_search_list'].length === 0) {
        return this.teachers;
      }
      return this.$store.getters['Teachers/get_search_list'];
    },
    is_old() {
      if (moment(this.plan_for_date) < moment().subtract('days', 1)) return true;
      return false;
    },
  },

  methods: {
    color(row) {
      if (row.substitute.lastname === 'offen') {
        return 'alert';
      }
      return '';
    },
    substchange(event) {
      console.log('EVENT-NAME: ', event);
      this.$store.commit('Plans/mutate_remove_double', event);
    },
    make_plan() {
      this.$store.dispatch('Plans/make_plan').then(() => {
        this.$buefy.toast.open({
          message: 'Plan aktualisiert.',
          type: 'is-success',
        });
      });
    },
    close_plan_modal() {
      this.plan_modal = false;
    },
    show_reg_plan() {
      this.plan_modal = true;
    },
    go_next() {
      this.plan_for_date = moment(this.plan_for_date).add(1, 'days')['_d'];
    },
    go_back() {
      this.plan_for_date = moment(this.plan_for_date).subtract(1, 'days')['_d'];
    },
    get_plan() {
      return this.$store.dispatch('Plans/get_plan', this.plan_for_date);
    },
    save_plan() {
      this.$store.dispatch('Plans/save_plan').then((response) => {
        if (response) {
          this.$buefy.toast.open({
            message: 'Vertretungsplan wurde gespeichert',
            type: 'is-success',
          });
          this.edit = false;
        } else {
          this.$buefy.toast.open({
            message: 'Vertretungsplan konnte nicht gespeichert werden.',
            type: 'is-danger',
          });
        }
      });
    },
    send_plan() {
      this.$store.dispatch('Plans/send_plan', this.plan_for_date).then((response) => {
        if (response) {
          this.$buefy.toast.open({
            message: 'Vertretungsplan wurde versendet',
            type: 'is-success',
          });
          this.edit = false;
        } else {
          this.$buefy.toast.open({
            message: 'Vertretungsplan konnte nicht versendet werden.',
            type: 'is-danger',
          });
        }
      });
    },
    add_sub() {
      this.edit_modal = true;
    },
    save_sub() {
      this.entry.type = 'm';
      this.$store.commit('Plans/save_sub', this.entry).then((response) => {
        console.log(response);
      });
    },
    reset_selected() {
      this.edit_modal = false;
    },
    search() {
      if (this.name === '') return;
      this.$store.dispatch('Teachers/search_teacher', this.name);
    },
    search_sub() {
      if (this.name === '') return;
      this.$store.dispatch('Teachers/search_teacher', this.name_sub);
    },
    select_missing(teacher) {
      this.entry.missing = teacher;
    },
    select_sub(teacher) {
      this.entry.substitute = teacher;
    },
  },
};
</script>

<style lang="scss">

.dashboard {
  background-color: white;
  .table {
    tr.is-selected {
      background-color: var(--secondary-color)!important;
    }
    .tc{
      padding: 0pt;
    }
    tr {
      cursor: pointer;
    }
    .textarea {
      min-height: 3rem !important;
    }
    .dropdown {
      width: 100% !important;
    }
  }
}

.alert {
  background-color: rgb(255, 94, 94) !important;
}
</style>
