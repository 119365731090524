import axios from 'axios';
import helpers from '../../mixins/helpers';

const config = {
  onUploadProgress: (progressEvent) => {
    this.progress = (Math.round((progressEvent.loaded * 100) / progressEvent.total));
  },
};

const state = {
  progress: 0,
};

const getters = {
};

const actions = {
  async upload_files(context, payload) {
    const formData = new FormData();
    console.log(formData);
    formData.append('image', payload);

    const res = await axios.post(`${helpers.apihost()}upload_files`, formData, config);
    return res.data;
  },
  async importCSV(context, payload) {
    const formData = new FormData();
    formData.append('csv', payload.csv);
    formData.append('csv_lang', payload.lang);
    formData.append('group_id', payload.group.id);
    formData.append('client_id', payload.group.client_id);

    const res = await axios.post(`${helpers.apihost()}importCSV`, formData, config);
    return res.data;
  },
  async upload_poster(s, poster) {
    if (poster !== []) {
      const formData = new FormData();
      formData.append('poster', poster);
      const res = await axios.post(`${helpers.apihost()}upload_poster`, formData, config);
      return res;
    }
    return null;
  },
  async upload_course_img(s, courseImg) {
    if (courseImg !== []) {
      const formData = new FormData();
      formData.append('course_img', courseImg);
      const res = await axios.post(`${helpers.apihost()}upload_course_img`, formData, config);
      return res;
    }
    return null;
  },
  async upload_assignment_img(s, assignmentImg) {
    if (assignmentImg !== []) {
      const formData = new FormData();
      formData.append('assignment_img', assignmentImg);
      const res = await axios.post(`${helpers.apihost()}upload_assignment_img`, formData, config);
      return res;
    }
    return null;
  },
  async upload_qc_pdf(s, array) {
    if (array.length !== 0) {
      const formData = new FormData();
      for (let i = 0; i < array.length; i += 1) {
        formData.append('file', array[i]);
      }
      console.log(array.length);
      if (array.length === 1) formData.append('singlefile', true);
      if (array.length > 1) formData.append('singlefile', false);
      const res = await axios.post(`${helpers.apihost()}upload_qc_pdf`, formData, config);
      if (res.status === 200) {
        return true;
      }
    }
    return null;
  },
  async upload_res(s, payload) {
    if (payload.array.length !== 0) {
      const formData = new FormData();
      for (let i = 0; i < payload.array.length; i += 1) {
        formData.append('file', payload.array[i]);
      }
      formData.append('course_id', payload.course_id);
      if (payload.array.length === 1) formData.append('singlefile', true);
      if (payload.array.length > 1) formData.append('singlefile', false);
      const res = await axios.post(`${helpers.apihost()}upload_res`, formData, config);
      console.log(res.data.resources);
      return res.data.resources;
    }
    return null;
  },
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
