import Vue from 'vue';
import VueRouter from 'vue-router';
import VueAxios from 'vue-axios';
import axios from 'axios';
import Dashboard from '../views/Dashboard.vue';
import Profile from '../views/Profile.vue';
import ResetPassword from '../views/ResetPassword.vue';
import LoginHome from '../views/LoginHome.vue';
import store from '../store/index';

Vue.use(VueRouter);
Vue.use(VueAxios, axios);

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { scrollToTop: true },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: 'admin' */ '../views/Account.vue'),
  },
  {
    path: '/teacher_management',
    name: 'TeacherManagement',
    component: () => import(/* webpackChunkName: 'admin' */ '../views/TeacherManagement.vue'),
  },
  {
    path: '/agreements',
    name: 'Agreements',
    component: () => import(/* webpackChunkName: 'admin' */ '../views/Agreements.vue'),
  },
  {
    path: '/standby',
    name: 'Vertretungen',
    component: () => import(/* webpackChunkName: 'admin' */ '../views/Standby.vue'),
  },
  {
    path: '/groups',
    name: 'Groups',
    component: () => import(/* webpackChunkName: 'admin' */ '../views/Groups.vue'),
  },
  {
    path: '/resetpassword/:user_id',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/:client?',
    name: 'LoginHome',
    component: LoginHome,
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  duplicateNavigationPolicy: 'ignore',
  scrollBehavior(to) {
    if (to.hash) {
      const id = to.hash.substring(1);
      const elem = document.getElementById(id);
      const offset = parseFloat(getComputedStyle(elem).scrollMarginTop);
      return {
        selector: to.hash,
        offset: { y: offset },
        behavior: 'smooth',
      };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const allowedAccess = ['LoginHome', 'TypeA', 'TypeB', 'TypeC', 'DataProtection', 'Impressum', 'Register'];
  if (to.name === 'Course' && store.getters['User/is_loggedin'] === false) {
    const payload = { cid: to.params.name };
    store.dispatch('User/login', payload).then((response) => {
      if (response) {
        const bodyStyles = document.body.style;
        bodyStyles.setProperty('--primary-color', store.getters['User/client'].pcolor);
        bodyStyles.setProperty('--secondary-color', store.getters['User/client'].scolor);
        bodyStyles.setProperty('--primary-color-text', store.getters['User/client'].pcolortext);
        bodyStyles.setProperty('--secondary-color-text', store.getters['User/client'].scolortext);
        if (store.getters['User/user'].hp) {
          next();
        } else {
          const uid = store.getters['User/user'].id;
          next({ name: 'ResetPassword', params: { user_id: uid } });
        }
      }
    });
  } else if (to.name === 'ResetPassword') {
    if (to.params.user_id) {
      const payload = { uid: to.params.user_id };
      store.dispatch('User/login', payload).then((response) => {
        if (response) {
          const bodyStyles = document.body.style;
          bodyStyles.setProperty('--primary-color', store.getters['User/client'].pcolor);
          bodyStyles.setProperty('--secondary-color', store.getters['User/client'].scolor);
          bodyStyles.setProperty('--primary-color-text', store.getters['User/client'].pcolortext);
          bodyStyles.setProperty('--secondary-color-text', store.getters['User/client'].scolortext);
          next();
        }
      });
      next();
    } else {
      next({ name: 'ResetPassword' });
    }
  } else if (!allowedAccess.includes(to.name) && store.getters['User/is_loggedin'] === false) {
    next({ name: 'LoginHome' });
  } next();
});

export default router;
