<template>
  <div class="foota">
    <div class="background">
      <div class="responsive-pad">
        <span v-if="is_loggedIn">
          <div v-if="user.client_id.footer_txt" v-html="user.client_id.footer_txt"
          class="padding-res"></div>
          <div v-else v-html="gobran_txt" class="padding-res"></div>
        </span>
        <span v-else>
          <img class="brand" src="~@/assets/images/lg-gobran-footer-1-1.png">
          <div v-html="gobran_txt" class="padding-res"></div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gobran_txt: '<p class="more-weight">Yo footer...</p>',
    };
  },
  computed: {
    is_loggedIn() {
      return this.$store.getters['User/is_loggedin'];
    },
    user() {
      return this.$store.getters['User/user'];
    },
  },
};
</script>

<style lang="scss">
.foota {
  font-family: "Noto Sans";
  width: 100%;
  bottom: 0;

  .background {
    background-color: var(--secondary-color) !important;
    padding: 2rem 0 !important;
    margin-top: 1rem;
  }

  p {
    color: var(--secondary-color-text)!important;
    font-size: 0.9em;
  }

  .brand {
    height: 30px !important;
    @media only screen and (max-width: 900px) {
      padding-left: .5rem;
    }
    @media only screen and (min-width: 901px) {
      padding-left: 6rem;
    }
  }

  .more-weight {
    font-weight: 900;
  }

  .less-weight {
    font-weight: 100;
  }
}
</style>
